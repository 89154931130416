import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextPageComponent from "../components/shared/text-page"

const PaymentsPage = () => (
  <Layout forceFixedHeader={true} page={"payments"}>
    <SEO title="Платежная информация" noindex="noindex"/>
    <TextPageComponent
      title={"Платежная информация"}>
      <h3>Правила оплаты и безопасность платежей, конфиденциальность информации</h3>
      <p>
        Оплата банковскими картами осуществляется через <a href="http://alfabank.ru/" target="_blank" rel="nofollow">АО «АЛЬФА-БАНК»</a>. К оплате
        принимаются карты VISA, MasterCard, МИР.
      </p>
      <div className={TextPageComponent.gallery}>
        <div><img src={require("../images/payments/alfabank.png")} alt={"АО АльфаБанк"}/></div>
        <div><img src={require("../images/payments/visa.png")} alt={"Платежная система Visa"}/></div>
        <div><img src={require("../images/payments/mastercard.png")} alt={"Платежная система MasterCard"}/></div>
        <div><img src={require("../images/payments/mir.png")} alt={"Платежная система МИР"}/></div>
      </div>
      <p>
        Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa,
        MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа,
        для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи.
        Ввод данных банковской карты осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».
      </p>
      <p>
        На странице для ввода данных банковской карты потребуется ввести <b>данные банковской карты</b>: номер карты,
        имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код
        Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код
        безопасности — это три цифры, находящиеся на обратной стороне карты.
      </p>
      <p>
        Далее вы будете перенаправлены на страницу Вашего банка для ввода кода безопасности, который придет к Вам в СМС.
        Если код безопасности к Вам не пришел, то следует обратиться в банк выдавший Вам карту.
      </p>
      <h3>Случаи отказа в совершении платежа</h3>
      <ul>
        <li>банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись
          в Ваш Банк;
        </li>
        <li>недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы
          можете узнать, обратившись в банк, выпустивший банковскую карту;
        </li>
        <li>данные банковской карты введены неверно;</li>
        <li>
          истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты
          (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать,
          обратившись в банк, выпустивший банковскую карту;
        </li>
      </ul>
      <p>
        По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться
        по следующим телефонам: 8 (846) 211-00-72.
      </p>
      <p>
        Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является
        конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде
        и не сохраняются на нашем Web-сервере.
      </p>
    </TextPageComponent>
  </Layout>
)

export default PaymentsPage
